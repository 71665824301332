import React, { useEffect } from 'react'
import Assets from '../../assets/_index'


const History = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | History'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
      <section className="breadcrumb blog_bg">
        <div className="container">
          <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb_iner">
            <div className="breadcrumb_iner_item">
              <h2>History</h2>
            </div>
            </div>
          </div>
          </div>
        </div>
      </section>

      <section className='container-history'>
        <div className='container box_1170'>
        <h3 className='text-heading history-title'>History of MARELCO</h3>
        <div className='row'>
          <div className='col-md-6'>
          <p className='history-content'>
            Marinduque before the 70's has nothing to be proud of except for being 
            known as one of the  most peaceful province in the country endowed with the natural beauty of nature. 
            Progress  and development has been very elusive, primarily owing to the absence of the vital 
            infrastructure  proven to be only gained from the benefits of electricity.
          </p>
          <br />
          <p className='history-content'>
            The monotonous of that same old way of life in Marinduque was eventually 
            set to a break upon  the wild aspirations and dreams of a few honorable Marinduquenos whom we 
            aptly call the harbingers  and architects of MARELCO and subsequently the people who manned it, 
            were finally forged into  reality. Thus, Marinduque has come to a new process – a transformation 
            through which these dreams  and aspirations they worked on had underwent a change. And so MARELCO 
            now, an electric service  Cooperative catering for the electricity needs of the people of Marinduque.
          </p>
          </div>
          <div className='col-md-6'>
          <div className='container-youtube'>
            <iframe className='mbr-embedded-video' src='https://www.youtube.com/embed/ZkS3UV1w--I?rel=0&amp;showinfo=0&autoplay=1&loop=1&playlist=ZkS3UV1w--I' title='title' frameBorder={0} allowFullScreen />
          </div>
          </div>
        </div>
        </div>
      </section>

      <section className='container-history'>
        <div className='container box_1170'>
        <h3 className='text-heading history-title'>The Conception <br /><small>1973</small></h3>
        <p className='history-content'>
          It was through the unrelenting effort of the prominent couple of Marinduque, 
          Honorable Carmencita O. Reyes, the then delegate of Marinduque to the Constitutional Convention, and Hon. 
          Edmundo M. Reyes, former Commissioner of Immigration and Deportation that MARELCO was organized in the early 
          part of 1973. The two made representations with the National Electrification Administration to conduct 
          a study which would lead to the organization of one electric cooperative that would serve the whole province 
          of Marinduque. The result of the study was not very encouraging, but Madam Carmencita O. Reyes remained 
          unfazed because of her stern belief that this province will never be developed without electricity. 
          At that point in time, only the poblaciones of the six (6) municipalities were being served with electricity 
          through the municipal government-owned systems and one (1) privately owned franchise.
        </p>
        <br />
        <p className='history-content'>
          With the passage of PD 269, through proper representation with the Administrator 
          of the National Electrification Administration (NEA), Gen Pedro G. Dumol and its Board, the initial stages 
          for the organization of MARELCO was set. Finally, in March 1973, a team from NEA and CAO (Cooperative 
          Administration Office) organized and established the Provincial Electric Cooperatives Team (PECT) and 
          subsequently the District Electrification Committees (DECS) whose chairmen automatically became the 
          Incorporators and Board of Directors of Marelco.
        </p>
        <br />
        <p className='history-content'>
          Thus on March 25, 1973, the Marinduque Electric Cooperative was organized and 
          registered and incorporated on March 27, 1973 with Registration No. 43.
        </p>
        <br />
        <p className='history-content'>
          Immediately thereafter, the cooperative obtained Loan A from the National 
          Electrification Administration (NEA) amounting to P20,447,000.00 to finance the construction and operation 
          of its electric power system consisting of electric generation equipment, transmission, distribution 
          and service lines, substation transformers, meters and other equipment necessary for its operation. The 
          loan was payable in 35 years with 2% interest rate. The loan was secured by a mortgage by the cooperative 
          to the NEA of the assets acquired by the loan itself.
        </p>
        </div>
      </section>

      <section className='container-history'>
        <div className='container box_1170'>
        <div className='row'>
          <div className='col-md-6'>
          <div className='container-image history-image-caption-bottom'>
            <img src={Assets.Hist_img1} alt='The Incorporators' />
            <br />
            <br />
            <br />
            <h4>The Incorporators</h4>
            <p>
            Romulo Sto. Domingo - Boac - Board president <br />
            Cesar U. Sevilla - Gasan - Vice-President <br />
            Isidoro S.. Selva - Buenavista <br />
            Constancio Marte - Mogpog <br />
            Jose Maloles - Sta. Cruz <br />
            Aniceto Rosales - Torrijos <br />
            </p>
            <br />
          </div>
          </div>
          <div className='col-md-6'>
          <div className='container-image history-image-caption-bottom'>
            <img src={Assets.Hist_img2} alt='Engr. Cesar U. Sevilla' />
            <br />
            <br />
            <br />
            <h4>Engr. Cesar U. Sevilla</h4>
            <p>
            First General Manager on June 28, 1973. <br />
            He was replaced as Vice -President <br />
            by Mr. Mauro J. Corral. <br />
            </p>
          </div>
          </div>
        </div>
        </div>
      </section>

      <section className='container-history'>
        <div className='container box_1170'>
        <ul className='unordered-list history-content'>
          <li>
          The initial energization was made possible in December 19, 1973 with the operation of the Interim Power 
          Plant in Murallon, Boac consisting of two (2) 200 KW White Superior Generating Units, and utilizing the 
          old acquired distribution system of Festin Electric System providing electricity in Boac Poblacion.
          </li>
          <br />
          <li>
          Mogpog Electric System was taken over and energized on March 11, 1975 providing electricity in Mogpog 
          Poblacion Gasan Electric System was taken over and energized on May 17, 1976 providing electricity in 
          Gasan Poblacion Construction of distribution system started and on June 30, 1977 the backbone lines 
          connecting Boac, Mogpog and Gasan were completed and energized consisting of 24.842 kms. 3 phase lines, 
          20.805 kms. Secondary lines, 0.307 kms V-phase lines and 1.439 kms 1-phase lines.
          </li>
          <br />
          <li>
          On March 18, 1977, construction of backbone lines to Buenavista started and was energized on December 
          22, 1978. The Backbone lines from Mogpog to Sta. Cruz was completed on June 30, 1980 but due to problems 
          on phasing out the old system, energization took place only on July 1, 1982 during the time of 
          Mr. Mejardo P. Arcelo Acting General Manager from the National Electrification Administration.
          </li>
          <br />
          <li>
          Loan B was released on December 16, 1981 in the amount of P9,200,000.00. This has made the construction 
          of backbone lines to Torrijos and its energization in June 28, 1982 marked the energization of the total 
          coverage area.
          </li>
        </ul>
        </div>
      </section>

      <section className='container-history'>
        <div className='container box_1170'>
        <div className='row'>
          <div className='col-md-6'>
          <div className='container-image history-image-caption-bottom'>
            <img src={Assets.Hist_img3} alt='Blessing and Inauguration of the Marelco Headquarters' />
            <br />
            <br />
            <br />
            <p>
            Blessing and Inauguration of the Marelco Headquarters
            took place on September 3, 1978.
            <br />
            <br />
            Distribution Lines to Sta. Cruz was completed on June 30, 1980
            but energization took place only on July 1, 1982.
            </p>
            <br />
          </div>
          </div>
          <div className='col-md-6'>
          <div className='container-image history-image-caption-bottom'>
            <img src={Assets.Hist_img4} alt='Mejardo P. Arcelo' />
            <br />
            <br />
            <br />
            <p>
            Mejardo P. Arcelo was designated
            Acting General Manager on November 23, 1981.
            </p>
          </div>
          </div>
        </div>
        </div>
      </section>

      <section className='container-history'>
        <div className='container box_1170'>
        <h3 className='text-heading history-title'>The Emergence of a New Dawn <br /><small>1985 - 1995</small></h3>
        <p className='history-content'>
          AGM Arcelo was recalled to NEA on July 16, 1987 and designated as Acting GM of 
          LUELCO. A Committee of COICs composed of Department Heads was created
        </p>
        <br />
        <div className='row'>
          <div className='col-md-6' style={{margin: 'auto 0'}}>
          <p className='history-content'>
            In 1987, two typhoons hit hard the province leaving it in total darkness
            for about four months.
          </p>
          <br />
          <p className='history-content'>
            Majority of the employees were “forced leave”.
          </p>
          </div>
          <div className='col-md-6'>
          <div className='container-youtube'>
            <img src={Assets.Hist_img5} alt='Engr. Cesar U. Sevilla' />
          </div>
          </div>
        </div>
        </div>
      </section>

      <section className='container-history'>
        <div className='container box_1170'>
        <ul className='unordered-list history-content'>
          <li>
          On February 5, 1988, Mr. Arcelo resumed his functions and responsibilities as AGM of Marelco and focused 
          on three top priorities:
          <ul className='unordered-list'>
            <li>Secure loan from NEA for rehabilitation of lines.</li>
            <li>Gradual recall of employees indispensable to the operation.</li>
            <li>Negotiation for NPC take-over.</li>
          </ul>
          </li>
          <br />
          <li>
          March 5, 1988 – the BOD approved a Loan Contract with NEA in the amount of P7.2 M for the restoration 
          of power in the coverage area.
          </li>
          <br />
          <li>
          On March 19, 1988, the BOD confirmed an Emergency Loan from NEA for the rehabilitation of the system, 
          which was granted on June 1988 in the amount of P2,485,000.00.
          </li>
          <br />
          <li>
          Power Supply was totally restored on April 26, 1988.
          </li>
          <li>
          With the implementation of Pres. Aquino’s Nationwide Subsidy Program for ECs, on May 13, 1988, the 
          National Power Corp. took over the supply of power at a subsidized rate of P2.50/kwh at a 20 hour a day, 
          starting June 1, 1988, with Marcopper as its source of power.
          </li>
          <li>
          On December 17, 1988 AGM Arcelo left Marelco and Rolando Los Banos, MSD Manager was designated 
          Officer-in-Charge.
          </li>
          <li>
          His term was cut short by the designation of Engr. Ferdinand Pamintuan as NEA Acting General Manager 
          on May 19, 1989.
          </li>
          <li>
          In 1990, the BOD approved Res. No. 48-90 approving the request of NPC to utilize Marelco Power Plant 
          at Bantad, Boac to augment and stabilize power supply requirement.
          </li>
          <li>
          On June 16, 1990 the Lipata PJM line construction took place through Advance payment scheme, which 
          brought additional revenue for about P768,000.00 per month.
          </li>
          <li>
          Energization and Switch-on Ceremony took place on December 12, 1990 with former NEA Administrator, 
          Rodrigo Cabrera.
          </li>
          <li>
          On March 25, 1991, NPC lifted its socialized rate of P2.50/kwh making Marelco’s rate at P3.95/kwh.
          </li>
          <li>
          In March 1993, AGM Pamintuan left Marelco a Category A coop, with 99% Collection Efficiency and 
          14% Systems Loss.
          </li>
          <li>
          Engr. Carlito L. Mistal, TSD Manager was designated Officer-In-Charge until the appointment of 
          Mejardo P. Arcelo as regular General Manager on April 1, 1994 after a rigid selection process 
          conducted by NEA.
          </li>
          <li>
          In August 1995, Marelco was adjudged Category A+ coop and bagged three top awards on the ocassion 
          of NEA’s 26th Anniversary.  
          </li>
          <li>
          Top Performing Electric Coop
          </li>
          <li>
          Lowest Systems Loss Award
          </li>
          <li>
          First Price “Get the Right Photovoltaic Site” in connection with the Phil. German Special Energy 
          Program Search for ideal site of Solar Power Project. The 3 islets of Mongpong, Maniwaya and Polo 
          were selected.
          </li>
        </ul>
        </div>
      </section>

      <section className='container-history'>
        <div className='container box_1170'>
        <h3 className='text-heading history-title'>Girding New Challenges <br /><small>1996 - 2000</small></h3>
        <ul className='unordered-list history-content'>
          <li>
          On February 6, 1996, through BOD Res. No. 10, a NEW LOGO Design contest was launched.
          </li>
          <br />
          <li>
          Iluminado Luci of Tanza, Boac, Marinduque was adjudged Champion and awarded on the celebration 
          of RE Week in August 1997.
          </li>
          <br />
          <li>
          Another Reorganization took place in 1997, approved under Res. No. 08-96 which primary purpose is 
          to strengthen the role and expand the duties and responsibilities of the District Offices.
          </li>
          <br />
          <li>
          In March 1998, Marelco celebrated its 25th Founding Anniversary in time with the Centennial 
          Celebration of the country.
          </li>
          <br />
          <li>
          The first edition of the Marelco History for the first 25 years was published.
          </li>
          <br />
          <li>
          In January 1999, Mrs. Thelma L. Mayangitan was designated Officer-In-Charge vice GM Arcelo who 
          suffered from stroke. She served until June 1999.
          </li>
          <br />
          <li>
          In March 1999, the electrification of the 3 islets in Sta. Cruz – Mongpong, Maniwaya and Polo 
          was realized through a Memorandum of Undertaking among NPC, Marelco and the Provincial Government.
          </li>
          <br />
          <li>
          In July 1999, Engr. Jose Seguban Jr. Was assigned as Project Supervisor until September of the same year.
          </li>
          <br />
          <li>
          Salary Upgrading also took effect in August 1999 based on the new classification – Medium Cooperative.
          </li>
          <br />
          <li>
          In October 1999 a Structural Modification for the District Offices was undertaken to give way to its 
          institutionalization.
          </li>
          <br />
          <li>
          In 2000 a Memorandum of Agreement was entered into by and among MARELCO, DPWH and Placer Dome Technical 
          Services for the Electrification of Boac River Valley.
          </li>
          <br />
          <li>
          This marked the total electrification of Boac.
          </li>
          <br />
          <li>
          The position of General Manager was opened due to optional retirement of GM Arcelo in July 2000
          </li>
          <br />
          <li>
          Mr. Wilredo Acasio took over until the appointment of Mr. Eduardo Q. Bueno as Acting General Manager in 
          January 2001.
          </li>
        </ul>
        </div>
      </section>

      <section className='container-history'>
        <div className='container box_1170'>
        <h3 className='text-heading history-title'>Setting A New Direction <br /><small>2001 - 2005</small></h3>
        <ul className='unordered-list history-content'>
          <li>
          RA 9136 or the Electric Power Industry Reform Act was enacted and implemented in June 2001.
          </li>
          <br />
          <li>
          A rate reduction of P.0969 was effected in 2002 due to Loan Condonation as a result of the implementation 
          of RA 9136.
          </li>
          <br />
          <li>
          Another Salary upgrading for officers and employees was approved by the Board in October 2003.
          </li>
          <br />
          <li>
          In December 2003, due to the Mandatory Retirement of GM Eduardo Q. Bueno pursuant to the provisions of 
          RA 9136, the Board appointed Officers-in-Charge who equally served for four months each in 2004, namely:
          <ul className='unordered-list'>
            <li>Agnes S. Espino – January to April</li>
            <li>Thelma L. Mayangitan - May to August</li>
            <li>Engr. Carlito Mistal – September to December</li>
          </ul>
          </li>
          <br />
          <li>
          In February 2004, Mr. Eduardo Q. Bueno was hired as Marelco’s Consultant pending appointment of a Regular 
          General Manager per Board Resolution No. 2004-08 and was extended in January 2005 per Board Resolution 
          No. 2005-0.
          </li>
          <br />
          <li>
          The Conversion to CDA was becoming a hot issue. So the Board created an Ad Hoc Committee to study its 
          advantages and disadvantages to the coop and the member-consumers.
          </li>
          <br />
          <li>
          Marelco was categorized “A” Large cooperative in 2004.
          </li>
          <br />
          <li>
          After the three Department Managers were given opportunity to be the OICs in 2004, the Board created 
          Executive Committee and Management Committee chaired by William Bobis in 2005 to handle the day to day 
          operation and management of the coop.
          </li>
          <br />
          <li>
          The Board approved a Resolution for the acquisition of Electronic Billing System in September 2005 and 
          the same was awarded to the qualified supplier in December 2005.
          </li>
          <br />
          <li>
          The Conversion to CDA was becoming a hot issue. So the Board created an Ad Hoc Committee to study its 
          advantages and disadvantages to the coop and the member-consumers.
          </li>
          <br />
          <li>
          Marelco was categorized “A” Large cooperative in 2004.
          </li>
          <br />
          <li>
          After the three Department Managers were given opportunity to be the OICs in 2004, the Board created 
          Executive Committee and Management Committee chaired by William Bobis in 2005 to handle the day to day 
          operation and management of the coop.
          </li>
          <br />
          <li>
          The Board approved a Resolution for the acquisition of Electronic Billing System in September 2005 
          and the same was awarded to the qualified supplier in December 2005.
          </li>
          <br />
          <li>
          As mandated by RA 9136, the Board upon selecting IFC as Transaction Advisor, consequently signed the 
          Power Supply Agreement in September 2005, between Marelco and 3i Powergen for the supply of electricity 
          to the province of Marinduque for the next 15 years.
          </li>
          <br />
          <li>
          On November 1, 2005, Mr. Eduardo Q. Bueno was appointed as Regular General Manager.
          </li>
        </ul>
        </div>
      </section>
      
      <section className='container-history'>
        <div className='container box_1170'>
        <h3 className='text-heading history-title'>Bringing Out the Best <br /><small>2008</small></h3>
        <p className='history-content'>
          Towards the beginning of 2008, Officers and Employees are all prepared, 
          committed and optimistic to bring the best for MARELCO and its 38,969 member-consumers.
        </p>
        </div>
      </section>
    </>

  )
  
}


export default History
