
import React,{useEffect} from "react";



const DistrictElection = ( ) => {

 useEffect( () => {
    document.title = 'MARELCO Website | District Election'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    <>
        <section className='breadcrumb blog_bg'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='breadcrumb_iner'>
                            <div className='breadcrumb_iner_item'>
                            <h2>District Election</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    </>
  )

}
export default DistrictElection