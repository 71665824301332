import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Page from './pages/_index'



ReactDOM.createRoot( document.getElementById( 'root' ) ).render(
  <BrowserRouter basename='/'>
    <Routes>
      <Route path='/' element={ <Page body="Home" /> } />
      <Route path='/home' element={ <Page body="Home" /> } />

      <Route path='/about_us/history'  element={ <Page body="AboutUs_History" /> } />
      <Route path='/about_us/mission_vision' element={ <Page body="AboutUs_MissionVision" /> } />
      <Route path='/about_us/board_of_directors' element={ <Page body="AboutUs_BOD" /> } />
      <Route path='/about_us/the_management' element={ <Page body="AboutUs_TheManagement"/> } />

      <Route path='/news_and_updates/whats_new' element={ <Page body="NewsAndUpdates_WhatsNew" /> } />
      <Route path='/news_and_updates/power_rates' element={ <Page body="NewsAndUpdates_PowerRates" /> } />
      <Route path='/news_and_updates/generation_charges' element={ <Page body="NewsAndUpdates_GenerationCharges" /> } />
      <Route path='/news_and_updates/job_vacancy' element={ <Page body="NewsAndUpdates_JobVacancy" /> } />

      <Route path='/members_consumers_owners/electrical_connection_application' element={ <Page body="MCO_ElectricalConnectionApplication" /> } />
      <Route path='/members_consumers_owners/pmos' element={<Page body = "MCO_PMOS" />} />
      <Route path='/members_consumers_owners/senior_citizen_discount_application' element={ <Page body="MCO_SeniorCitizenDiscountApplication" /> } />
      <Route path='/members_consumers_owners/downloadable_forms' element={ <Page body="MCO_DownloadableForms" /> } />
      <Route path='/members_consumers_owners/infos_and_safety_tips' element={ <Page body="MCO_InfosAndSafetyTips" /> } />
      <Route path='/members_consumers_owners/newsletter' element={ <Page body="MCO_Newsletter" /> } />
      <Route path='/members_consumers_owners/district_election' element={<Page body="MCOS_DistrictElection"/> } />

      <Route path='/contact_us' element={ <Page body="ContactUs" /> } />

      <Route path='*' element={ <Page Body='PageNotFound' /> } />
    </Routes>
  </BrowserRouter>
)