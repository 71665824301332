import React, { useEffect } from 'react'
// import ReactPlayer from 'react-player'
// import Assets from '../../assets/_index'

const Newsletter = () => {

  useEffect( () => {
    document.title = 'MARELCO Website | Newsletter'
    document.body.removeAttribute( 'hidden' )
  }, [])

  return (
    
    <>
        <section className='breadcrumb blog_bg'>
            <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                <div className='breadcrumb_iner'>
                    <div className='breadcrumb_iner_item'>
                    <h2>Newsletter</h2>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        <section className='contact-section area-padding'>
            <div className='container'>
                <div className='text-center mb-3'>
                    {/* <button className='btn' type='button' data-toggle='collapse' data-target='#collapseBid2023' aria-expanded='false' aria-controls='collapseBid2023'> */}
                        <a href='/resources/files/newsletter/MARELCO_2024Issue2.pdf' download> 
                            <h3 className='whats-new-pdf-button-header mb-5 text-center'>MARELCO Newsletter 2024 Issue 2 (July-December 2024) </h3>
                        </a>
                    {/* </button> */}
                </div>

                {/* <div className='row align-middle fadeIn Up daley-1s'>
                            <div className='col-lg-4  mb-5'>
                              <ReactPlayer
                                className='react-player'
                                url={Assets.Distribution_process} alt='Power Distribution Process'
                                width='100%'
                                height='100%'
                                controls={true}
                              />
                              <div className='informational-videos mt-2'>Power Distribution Process &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <i class="fa fa-thumbs-up"></i>  &nbsp; &nbsp; &nbsp;
                                <i class="fa fa-heart"></i>
                              </div>
                              
                               
                            </div>
                            
                            <div className='col-lg-4 md-2 mb-5'>
                              <ReactPlayer
                                className='react-player'
                                url= {Assets.Distribution_system} alt='Distribution System'
                                width='100%'
                                height='100%'
                                controls={true}
                              />
                              <div className='informational-videos mt-2'>Power Distribution System  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <i class="fa fa-thumbs-up"></i>  &nbsp; &nbsp; &nbsp;
                                <i class="fa fa-heart"></i>
                              </div>
                            </div>
                            <div className='col-lg-4 md-2 mb-5'>
                            <ReactPlayer
                                className='react-player'
                                url={Assets.Power_outages} alt='Reason of Power Outages'
                                width='100%'
                                height='100%'
                                controls={true}
                              />
                              <div className='informational-videos mt-2'>Reason of Power Outages &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <i class="fa fa-thumbs-up"></i>  &nbsp; &nbsp; &nbsp;
                                <i class="fa fa-heart"></i>
                              </div>
                            </div>
                            
                          </div> */}
            
            

            </div>
        </section>

     
    </>
  )


}

export default Newsletter