import { useEffect, useState } from 'react'

import Home from './home'
import * as Component from '../components/_index'
import * as AboutUs from '../pages/about_us/_index'
import * as NewsAndUpdates from '../pages/news_and_updates/_index'
import * as MCO from '../pages/mco_information/_index'
import ContactUs from './contact_us'
import PageNotFound from './page_not_found'


const Pages = ( props ) => {


const [ pageBody, setPageBody ] = useState( <></> )
var location = props.body?.split( '_' )

  useEffect( () => {
    if ( !location ) { setPageBody( <PageNotFound /> ) }

    switch ( location[0] ) {
      case 'Home': setPageBody( <Home /> ); break;
      case 'AboutUs':
        switch ( location[1] ) {
          case 'History': setPageBody( <AboutUs.History /> ); break;
          case 'MissionVision': setPageBody( <AboutUs.MissionVision /> ); break;
          case 'BOD': setPageBody( <AboutUs.BOD /> ); break;
          case 'TheManagement': setPageBody( <AboutUs.TheManagement /> ); break;
          default: setPageBody( <Home /> ); break;
        }
        break;
      case 'NewsAndUpdates':
        switch ( location[1] ) {
          case 'WhatsNew': setPageBody( <NewsAndUpdates.WhatsNew /> ); break;
          case 'PowerRates': setPageBody( <NewsAndUpdates.PowerRates /> ); break;
          case 'GenerationCharges': setPageBody( <NewsAndUpdates.GenerationCharges /> ); break;
          case 'JobVacancy': setPageBody( <NewsAndUpdates.JobVacancy /> ); break;
          default: setPageBody( <Home /> ); break;
        }
        break;
      case 'MCO':
        switch ( location[1] ) {
          case 'ElectricalConnectionApplication': setPageBody( <MCO.ElectricalConnectionApplication /> ); break;
          case 'SeniorCitizenDiscountApplication': setPageBody( <MCO.SeniorCitizenDiscount /> ); break;
          case 'DownloadableForms': setPageBody( <MCO.DownloadableForms /> ); break;
          case 'InfosAndSafetyTips': setPageBody( <MCO.InfosAndSafetyTips /> ); break;
          case 'PMOS': setPageBody( <MCO.PMOS /> ); break;
          case 'Newsletter': setPageBody(<MCO.Newsletter/>); break;
          case 'DistrictElection': setPageBody(<MCO.DistrictElection/>); break;
          default: setPageBody( <Home /> ); break;
        }
        break;
      case 'ContactUs': setPageBody( <ContactUs /> ); break;
      default: setPageBody( <PageNotFound /> ); break;
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Component.Header />

       { pageBody }
      
      <Component.Footer />
    </>
  )
}


export default Pages